import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"

import BackButton  from "../components/globals/back-button";

const AvisodePrivacidad = () => (
  <Layout footer={true} menuactive="noscroll">
    <SEO title="Aviso de Privacidad" />
    <div className="container">
      <div className="container-warped">
        <br />
        <br />
        <br />
        <br />
        <BackButton />
        <br />
        <br />
        <h1>AVISO DE PRIVACIDAD</h1>
        <br />
        <br />
        <h2>¿Quiénes somos?</h2>
        <br />
        <p className="txt-description">
          High-flyers S.C., mejor conocido como High-flyers, con domicilio en calle Canal María Candelaria 61, Col. Barrio 18, alcaldía Xochimilco, C.P. 16034, Ciudad de México, y portal de internet www.high-flyers.mx, es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:
        </p>
        <br />
        <br />
        <h2>¿Para qué fines utilizaremos sus datos personales?</h2><br />
        <p className="txt-description">
          Los datos personales que recabamos de usted:<br />
        </p>
        <ul>
          <li>Nombre</li>
          <li>Empresa</li>
          <li>Correo electrónico</li>
          <li>Teléfono</li>
          <li>Fecha de nacimiento</li>
          <li>Género</li>
          <li>Universidad</li>
        </ul>
        <p className="txt-description">
          Los utilizaremos para las siguientes finalidades que son necesarias para el servicio que solicita:
        </p>
        <ul>
          <li>
            Establecer comunicación continua con usted para comentarle acerca de su proceso de aplicación a la Academia de Software
          </li>
          <li>
            Establecer comunicación continua con usted para comentarle acerca de los proyectos y próximos eventos relacionados con High-flyers
          </li>
        </ul>
        <br />
        <h2>¿Dónde puedo consultar el aviso de privacidad integral?</h2>
        <br />
        <p className="txt-description">
          Para conocer mayor información sobre los términos y condiciones en que serán tratados sus datos personales, como los terceros con quienes compartimos su información personal y la forma en que podrá ejercer sus derechos ARCO, puede consultar el aviso de privacidad integral en la siguiente dirección electrónica www.high-flyers.mx o enviando un correo con su solicitud a hola@high-flyers.mx.
        </p>
      </div>
    </div>
    <div className="footer-padding bkg-white"></div>
  </Layout>
)

export default AvisodePrivacidad
