import React, { Component } from "react"
import { FaArrowLeft } from "react-icons/fa";

class BackButton extends Component {
  constructor(props) {
    super(props);
    this.backHistory = this.backHistory.bind(this);
  }

  backHistory() {
    window.history.go(-1);
  }

  render() {
    return (
      <button onClick={this.backHistory}>
        <FaArrowLeft /> Regresar
      </button>
    );
  }
}

export default BackButton